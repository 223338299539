import React, { useCallback } from 'react';
import { splitStringToArray } from '../../../lib/utils';

import './MiddleEllipsis.scss';

interface Props {
  children: any;
  width?: number | string;
}

const MiddleEllipsis: React.FC<Props> = ({ children, width }) => {
  const measuredParent = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      window.addEventListener('resize', () => {
        prepEllipse(node);
      });
      prepEllipse(node);
    }
  }, []);

  const prepEllipse = (node: HTMLDivElement) => {
    const parent = node.parentNode as HTMLDivElement;
    const child: any = node.childNodes[0] as HTMLElement;
    const txtToEllipse =
      parent.querySelector('.generic-responsive-middle-truncation') || child;

    if (child !== null && txtToEllipse !== null) {
      // (Re)-set text back to data-text if it exists.
      if (txtToEllipse.hasAttribute('data-text')) {
        txtToEllipse.textContent = txtToEllipse.getAttribute('data-text');
      }

      textEllipis(
        // Smaller width.
        node.offsetWidth > parent.offsetWidth ? parent : node,
        child,
        txtToEllipse
      );
    }
  };

  const textEllipis = (
    parentNode: HTMLDivElement,
    childNode: HTMLElement,
    txtNode: HTMLElement
  ) => {
    const childWidth = childNode.offsetWidth;
    const containerWidth = parentNode.offsetWidth;
    const txtWidth = txtNode.offsetWidth;
    const targetWidth = childWidth > txtWidth ? childWidth : txtWidth;

    if (targetWidth > containerWidth) {
      const str = txtNode.textContent || '';
      const stringArray = splitStringToArray(str);
      const txtChars = stringArray.length;

      const avgLetterSize = txtWidth / txtChars;
      const canFit =
        (containerWidth - (targetWidth - txtWidth)) / avgLetterSize;
      const delEachSide = (txtChars - canFit + 5) / 2;
      const endLeft = Math.floor(txtChars / 2 - delEachSide);
      const startRight = Math.ceil(txtChars / 2 + delEachSide);

      txtNode.setAttribute('data-text', txtNode.textContent || '');
      txtNode.textContent =
        stringArray.slice(0, endLeft).join('') +
        '...' +
        stringArray.slice(startRight).join('');
    }
  };

  return (
    <div
      ref={measuredParent}
      className='middle-ellipsis'
      style={{
        ...(width && { width: width }),
      }}
    >
      {children}
    </div>
  );
};

export default MiddleEllipsis;
