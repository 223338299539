import { Column, Row } from 'carbon-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import images from '../../../images/images';
import './LegendShortcuts.scss';

const LegendShortcuts = () => {
  const { t } = useTranslation('legendContainer');
  return (
    <div className='topology-legend-shortcuts'>
      <Row>
        <Column>
          <Row className='shortcut-item'>
            <Column className='legend-sub-section-label'>
              {t('shortcuts.expandAll')}
            </Column>
            <Column>
              <div className='shortcut-btn'>
                <div className='shift-key-icon'>
                  {images.shiftKeyLegendSvg()}
                </div>
                <div className='legend-sub-section-btn'>
                  {images.doubleClickLegendSvg()}
                </div>
              </div>
            </Column>
          </Row>
          <Row className='shortcut-item'>
            <Column className='legend-sub-section-label'>
              {t('shortcuts.singleNodeDrag')}
            </Column>
            <Column>
              <div className='shortcut-btn'>
                <div className='shift-key-icon'>
                  {images.shiftKeyLegendSvg()}
                </div>
                <div className='legend-sub-section-btn'>
                  <div className='dragButton'> {t('shortcuts.drag')}</div>
                </div>
              </div>
            </Column>
          </Row>
          <Row className='shortcut-item'>
            <Column className='legend-sub-section-label'>
              {t('shortcuts.select')}
            </Column>
            <Column>
              <div className='shortcut-btn'>
                <div className='legend-sub-section-btn'>
                  {images.singleClickLegendSvg()}
                </div>
              </div>
            </Column>
          </Row>
          <Row className='shortcut-item'>
            <Column className='legend-sub-section-label'>
              {t('shortcuts.expandCollapse')}
            </Column>
            <Column>
              <div className='shortcut-btn'>
                <div className='legend-sub-section-btn'>
                  {images.doubleClickLegendSvg()}
                </div>
              </div>
            </Column>
          </Row>
        </Column>
      </Row>
    </div>
  );
};

export default LegendShortcuts;
