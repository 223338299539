import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SkeletonPlaceholder } from 'carbon-components-react';
import { Tile, Button, Link, InlineNotification } from '@carbon/react';
import {
  ChevronUp16,
  ChevronDown16,
  Close16,
  Network_416,
  Launch16,
} from '@carbon/icons-react';
import {
  removeFiltersFromStorage,
  removeViewStateFromStorage,
} from '../../lib/utils';
import useAnalytics from '../../lib/useAnalytics';
import dates from '../../lib/dates';
import images from '../../images/images.js';
import { MAINTENANCE_START_WINDOW, MAINTENANCE_END_WINDOW } from '../../config';
import {
  Application,
  DeploymentEnvironment,
  Location,
  Cloud,
  Gateway,
  ResourceGroup,
  DeploymentEnvironmentSubtype,
} from '../../models/master';
import OverviewTile from '../../components/OverviewTile/OverviewTile';
import { EmptyState } from '../../components/EmptyState/EmptyState';
import analyticsData from '../../lib/analyticsEventData';
import { useApplications } from '../../hooks/useApplications';
import { AxiosError } from 'axios';
import { usePoliciesData } from '../../hooks/usePolicies';
import {
  useDeploymentEnvSubtypes,
  useDeploymentEnvsData,
} from '../../hooks/useDeploymentEnvs';
import { useGateways } from '../../hooks/useGateways';
import { useLocations } from '../../hooks/useLocations';
import { useResourceGroupsData } from '../../hooks/useResourceGroups';
import { useCloudsData } from '../../hooks/useClouds';
import { useTopology } from '../../hooks/useTopology';

import './LandingPage.scss';
import { VisibilityFlags } from '../../lib/enums';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const { pageViewed, trackButtonClicked } = useAnalytics();
  const { t } = useTranslation('landingPage');
  const [isExpanded, setExpanded] = useState(true);
  const [bannerOpen, setBannerOpen] = useState(true);

  const selectedAccount = window.localStorage.getItem('SELECTED_ACCOUNT');

  const authDetails = JSON.parse(
    window.localStorage.getItem(`ACCOUNT_DETAILS_${selectedAccount}`) ?? 'null'
  );
  const userName = authDetails
    ? authDetails?.username
      ? authDetails?.username
      : authDetails?.details?.UserName
    : '';

  const showMaintenanceBanner =
    window.localStorage.getItem('showMaintenanceBanner') === 'true'
      ? true
      : false;
  const showTechPreviewBanner =
    window.localStorage.getItem('showTechPreviewBanner') === 'true'
      ? true
      : false;
  const showMeshAgentUpgradeBanner =
    window.localStorage.getItem('showMeshAgentUpgradeBanner') === 'true'
      ? true
      : false;

  const defaultPermissionMap = {
    applications: true,
    policies: true,
    locations: true,
    deploymentEnvs: true,
    gateways: true,
    clouds: true,
    resourceGroups: true,
    topology: true,
  };

  const defaultServerErrorMap = {
    applications: false,
    policies: false,
    locations: false,
    deploymentEnvs: false,
    gateways: false,
    clouds: false,
    topology: false,
  };

  // Queries resource groups
  const {
    data: resourceGroups,
    error: resourceGroupError,
    isError: isResourceGroupError,
  } = useResourceGroupsData();
  if (isResourceGroupError) {
    const error = resourceGroupError as AxiosError;
    defaultPermissionMap.resourceGroups =
      error?.response?.status === 403 ? false : true;
  }

  // Queries applications
  const {
    data: applications,
    isLoading: loadingApplications,
    error: applicationError,
    isError: isApplicationError,
  } = useApplications();
  if (isApplicationError) {
    const error = applicationError as AxiosError;
    defaultPermissionMap.applications =
      error?.response?.status === 403 ? false : true;
    defaultServerErrorMap.applications =
      error.response!?.status >= 500 ? true : false;
  }

  const formattedApps = useMemo(
    () => formatApps(),
    [applications, resourceGroups, defaultPermissionMap]
  );

  // Queries policies
  const {
    data: policies,
    isLoading: loadingPolicies,
    error: policyError,
    isError: isPolicyError,
  } = usePoliciesData({
    fetchServices: true,
    fetchApplications: false,
    count: 3,
  });

  if (isPolicyError) {
    const error = policyError as AxiosError;
    defaultPermissionMap.policies =
      error?.response?.status === 403 ? false : true;
    defaultServerErrorMap.policies =
      error.response!?.status >= 500 ? true : false;
  }

  // Queries deployment environment subtypes
  const { data: deploymentEnvironmentSubtypes } = useDeploymentEnvSubtypes();

  // Queries deployment environment
  const {
    data: deploymentEnvironments,
    isLoading: loadingDeploymentEnvs,
    error: deploymentEnvError,
    isError: isDeploymentEnvError,
  } = useDeploymentEnvsData(VisibilityFlags.MANAGED);
  if (isDeploymentEnvError) {
    const error = deploymentEnvError as AxiosError;
    defaultPermissionMap.deploymentEnvs =
      error?.response?.status === 403 ? false : true;
    defaultServerErrorMap.deploymentEnvs =
      error.response!?.status >= 500 ? true : false;
  }
  const formatedDeploymentEnvironments = useMemo(
    () => formatDeploymentEnvironments(),
    [deploymentEnvironments, deploymentEnvironmentSubtypes]
  );

  // Queries clouds
  const {
    data: clouds,
    isLoading: loadingClouds,
    error: cloudError,
    isError: isCloudError,
  } = useCloudsData();
  if (isCloudError) {
    const error = cloudError as AxiosError;
    defaultPermissionMap.clouds =
      error?.response?.status === 403 ? false : true;
    defaultServerErrorMap.clouds =
      error.response!?.status >= 500 ? true : false;
  }

  // Queries locations
  const {
    data: locations,
    isLoading: loadingLocations,
    error: locationError,
    isError: isLocationError,
  } = useLocations(VisibilityFlags.MANAGED);
  if (isLocationError) {
    const error = locationError as AxiosError;
    defaultPermissionMap.locations =
      error?.response?.status === 403 ? false : true;
    defaultServerErrorMap.locations =
      error.response!?.status >= 500 ? true : false;
  }
  const formattedLocations = useMemo(
    () => formatLocations(),
    [locations, clouds]
  );

  // Queries gateways
  const {
    data: gateways,
    isLoading: loadingGateways,
    error: gatewayError,
    isError: isGatewayError,
  } = useGateways();
  if (isGatewayError) {
    const error = gatewayError as AxiosError;
    defaultPermissionMap.gateways =
      error?.response?.status === 403 ? false : true;
    defaultServerErrorMap.gateways =
      error.response!?.status >= 500 ? true : false;
  }
  const formattedGateways = useMemo(
    () => formatGateways(),
    [gateways, deploymentEnvironments, defaultPermissionMap]
  );
  const gatewayStats = [
    {
      key: 'all',
      title: t('statsList.total'),
      value: gateways?.length ?? 0,
    },
    {
      key: 'edge',
      title: t('statsList.edge'),
      value: formattedGateways.edgeCount,
      icon: images.EdgeGatewayIcon(),
    },
    {
      key: 'waypoint',
      title: t('statsList.waypoint'),
      value: formattedGateways.wpCount,
      icon: images.WaypointGatewayIcon(),
    },
  ];

  // Queries topology
  const {
    data: topologyData,
    isLoading: loadingTopology,
    error: topologyError,
    isError: isTopologyError,
  } = useTopology(VisibilityFlags.MANAGED);
  if (isTopologyError) {
    const error = topologyError as AxiosError;
    defaultPermissionMap.topology =
      error?.response?.status === 403 ? false : true;
    defaultServerErrorMap.topology =
      error.response!?.status >= 500 ? true : false;
  }

  const haveLocation = useMemo(
    () =>
      topologyData?._items.some((item: { entityType: string }) => {
        if (item?.entityType === 'location') {
          return true;
        }
        return false;
      }),
    [topologyData]
  );

  const closeBanner = (type: string) => {
    if (type === 'maintenance') {
      window.localStorage.setItem('showMaintenanceBanner', 'false');
    } else if (type === 'meshAgentUpgrade') {
      window.localStorage.setItem('showMeshAgentUpgradeBanner', 'false');
    } else {
      window.localStorage.setItem('showTechPreviewBanner', 'false');
    }
  };

  function navigateToTearsheet(type: string) {
    const url = type === 'APPLICATION' ? '/applications' : '/clouds';
    const analyticsData =
      type === 'APPLICATION'
        ? {
            CTA: 'Home, Register application link clicked',
            action: 'Register application link clicked from home page',
          }
        : {
            CTA: 'Home, Register cloud link clicked',
            action: 'Register cloud link clicked from home page',
          };
    trackButtonClicked(analyticsData);
    navigate(url, { state: { resourceType: type, navigateBack: true } });
  }

  function formatApps() {
    const modifiedApps = applications?.slice(0, 3).map((app: Application) => {
      const group = resourceGroups?.filter(
        (group: ResourceGroup) => group.resource_id === app.resource_group_id
      )[0];
      return {
        resource_id: app.resource_id,
        name: app.name,
        updated_at: app.updated_at,
        resourceGroupName: group?.name,
        resourceGroupPermission: defaultPermissionMap.resourceGroups,
      };
    });
    return modifiedApps;
  }

  function formatGateways() {
    let edge = 0;
    let wp = 0;
    let compactList: any[] = [];
    gateways?.map((gateway: Gateway, index: number) => {
      if (index < 3) {
        const location = locations?.locationsData?.find(
          (location: Location) => location.resource_id === gateway.location_id
        );
        compactList.push({
          name: gateway.name,
          type: gateway.type,
          health_status: gateway.health_status,
          resource_id: gateway.resource_id,
          location_name: location?.name ?? '',
          locationPermission: defaultPermissionMap.locations,
        });
      }
      if (gateway?.type === 'edge') {
        edge += 1;
      } else {
        wp += 1;
      }
    });

    return { gateways: compactList, edgeCount: edge, wpCount: wp };
  }

  function formatLocations() {
    const modifiedLocations = locations?.locationsData
      ?.slice(0, 3)
      .map((location: Location) => {
        const cloud = clouds?.filter(
          (cloud: Cloud) => cloud.resource_id === location.cloud_id
        )[0];
        return {
          resource_id: location.resource_id,
          name: location.name,
          cloud_id: location.cloud_id,
          cloud_name: cloud?.name,
          updated_at: location.updated_at,
        };
      });
    return modifiedLocations;
  }

  function getDeploymentEnvSubTypeName(subtype: string) {
    const deplEnvSubtype = deploymentEnvironmentSubtypes?.find(
      (deplEnvSubtype: DeploymentEnvironmentSubtype) =>
        deplEnvSubtype.type_code === subtype
    );

    return deplEnvSubtype ? deplEnvSubtype.type_name : '';
  }

  function formatDeploymentEnvironments() {
    const modifiedDeploymentEnv = deploymentEnvironments
      ?.slice(0, 3)
      .map((environment: DeploymentEnvironment) => ({
        resource_id: environment.resource_id,
        name: environment.name,
        subtypeName: getDeploymentEnvSubTypeName(environment.subtype),
        updated_at: environment.updated_at,
      }));

    return modifiedDeploymentEnv;
  }

  useEffect(() => {
    removeFiltersFromStorage();
    removeViewStateFromStorage();
  }, []);

  useEffect(() => {
    pageViewed('Home');
  }, []);

  return (
    <div className='landing-page-container'>
      {isExpanded ? (
        <div className='banner-container'>
          <div className='banner-content'>
            <div className='header'>{t('welcome', { user: userName })}</div>
            <div className='sections'>
              <div className='section'>
                <div className='subheader'>{t('getStarted.title')}</div>
                <div className='text'>{t('getStarted.text')}</div>
                <a
                  href={`https://www.ibm.com/docs/${navigator.language}/hybrid-cloud-mesh?topic=getting-started`}
                  rel='noopener noreferrer'
                  className='section-link get-started-doc-link'
                  onClick={() =>
                    trackButtonClicked(
                      analyticsData['Home'].events.getStartedLink.props,
                      analyticsData['Home'].events.getStartedLink.event
                    )
                  }
                  target='_blank'
                >
                  <span className='link-text'>{t('getStarted.link')}</span>
                  <span className='link-icon'>
                    <Launch16 />
                  </span>
                </a>
              </div>
              <div className='section'>
                <div className='subheader'>{t('cloud.title')}</div>
                <div className='text'>{t('cloud.text')}</div>
                <Link
                  onClick={() => navigateToTearsheet('CLOUD')}
                  className='section-link'
                >
                  {t('cloud.link')}
                </Link>
              </div>
              <div className='section'>
                <div className='subheader'>{t('application.title')}</div>
                <div className='text'>{t('application.text')}</div>
                <Link
                  onClick={() => navigateToTearsheet('APPLICATION')}
                  className='section-link'
                >
                  {t('application.link')}
                </Link>
              </div>
            </div>
          </div>
          <div className={'hero-image'}>{images.heroIllustration()}</div>

          <div className='banner-expander'>
            <Button
              hasIconOnly
              renderIcon={ChevronUp16}
              kind='ghost'
              tabIndex={0}
              onClick={() => setExpanded(false)}
              iconDescription={t('expandBanner')}
            />
          </div>
        </div>
      ) : (
        <div
          className={`banner-container collapsed-banner ${
            bannerOpen ? '' : 'closed-banner'
          }`}
        >
          <div className='welcome'>{t('welcome', { user: userName })}</div>
          <div className='close-banner'>
            <Button
              hasIconOnly
              renderIcon={Close16}
              kind='ghost'
              onClick={() => setBannerOpen(false)}
              iconDescription={t('closeBanner')}
            />
          </div>
          <div className='banner-expander'>
            <Button
              hasIconOnly
              renderIcon={ChevronDown16}
              kind='ghost'
              tabIndex={0}
              onClick={() => setExpanded(true)}
              iconDescription={t('collapseBanner')}
            />
          </div>
        </div>
      )}
      {showMaintenanceBanner ? (
        <div className='notification-container'>
          <InlineNotification
            lowContrast
            kind='warning'
            title={t('scheduledMaintenanceBanner.title')}
            subtitle={t('scheduledMaintenanceBanner.subtitle', {
              start: dates.getTimeWithDate(
                MAINTENANCE_START_WINDOW,
                t('scheduledMaintenanceBanner.utc')
              ),
              end: dates.getTimeWithDate(
                MAINTENANCE_END_WINDOW,
                t('scheduledMaintenanceBanner.utc')
              ),
            })}
            onClose={() => closeBanner('maintenance')}
          />
        </div>
      ) : null}
      {showMeshAgentUpgradeBanner ? (
        <div className='notification-container'>
          <InlineNotification
            lowContrast
            kind='info'
            title={t('meshAgentUpgradeBanner.title')}
            subtitle={t('meshAgentUpgradeBanner.subtitle')}
            onClose={() => closeBanner('meshAgentUpgrade')}
          />
        </div>
      ) : null}
      {showTechPreviewBanner ? (
        <div className='notification-container'>
          <InlineNotification
            lowContrast
            kind='info'
            title={t('techPreviewBanner.title')}
            subtitle={t('techPreviewBanner.subtitle')}
            onClose={() => closeBanner('techPreview')}
          />
        </div>
      ) : null}
      <Tile className='landing-page-content'>
        {loadingTopology ? (
          <SkeletonPlaceholder className='topology-card-skeleton' />
        ) : haveLocation && !isTopologyError ? (
          <div className='topology-card'>
            <div className='image'>{images.topologyCardImagePhase1()}</div>
            <div className='text'>
              <div className='header'>{t('topologyCard.title')}</div>
              <div className='description'>{t('topologyCard.subtitle')}</div>
              <Button
                kind='tertiary'
                size='md'
                className='banner-link'
                renderIcon={Network_416}
                onClick={() => {
                  trackButtonClicked(
                    analyticsData['Home'].events.canvasViewLink.props,
                    analyticsData['Home'].events.canvasViewLink.event
                  );
                  navigate('/topologies');
                }}
              >
                {t('topologyCard.buttonText')}
              </Button>
            </div>
          </div>
        ) : (
          <div className='empty-topology-state'>
            <div className='card-header'>{t('topologyCard.header')}</div>
            <EmptyState
              icon={
                !defaultPermissionMap.topology
                  ? images.notAuthorizedSvg()
                  : defaultServerErrorMap.topology
                  ? images.error500Icon()
                  : images.ResourceGroupEmpty()
              }
              header={
                !defaultPermissionMap.topology
                  ? t('403Error.header')
                  : defaultServerErrorMap.topology
                  ? t('500Error.title')
                  : t('emptyState.topology.header')
              }
              description={
                !defaultPermissionMap.topology
                  ? t('403Error.description')
                  : defaultServerErrorMap['topology']
                  ? t('500Error.description')
                  : t('emptyState.topology.description')
              }
            />
          </div>
        )}
        <div className='resource-tiles'>
          <OverviewTile
            resourceType='APPLICATION'
            header={t('topologyTile.applications')}
            data={formattedApps}
            emptyState={{
              icon: images.noApplicationsIcon(),
              header: t('emptyState.application.header'),
              description: t('emptyState.application.description'),
              buttonText: t('emptyState.application.buttonText'),
              link: '/',
            }}
            addText={t('emptyState.application.buttonText')}
            loading={loadingApplications}
            statsList={[
              {
                key: 'total',
                title: t('statsList.total'),
                value: applications?.length ?? 0,
              },
            ]}
            permission={defaultPermissionMap.applications}
            serverError={defaultServerErrorMap.applications}
          />
          <OverviewTile
            resourceType='POLICY'
            header={t('topologyTile.policies')}
            data={policies}
            emptyState={{
              icon: images.noPoliciesIcon(),
              header: t('emptyState.policy.header'),
              description: t('emptyState.policy.description'),
              buttonText: t('emptyState.policy.buttonText'),
              link: '/',
            }}
            addText={t('emptyState.policy.buttonText')}
            loading={loadingPolicies}
            statsList={[
              {
                key: 'total',
                title: t('statsList.total'),
                value: policies?.length ?? 0,
              },
            ]}
            permission={defaultPermissionMap.policies}
            serverError={defaultServerErrorMap.policies}
          />
          <OverviewTile
            resourceType='ENVIRONMENT'
            header={t('topologyTile.environments')}
            data={formatedDeploymentEnvironments}
            emptyState={{
              icon: images.noDeploymentEnvsIcon(),
              header: t('emptyState.deploymentEnv.header'),
              description: t('emptyState.deploymentEnv.description'),
              buttonText: t('emptyState.deploymentEnv.buttonText'),
              link: '/',
            }}
            addText={t('emptyState.deploymentEnv.buttonText')}
            loading={loadingDeploymentEnvs}
            statsList={[
              {
                key: 'total',
                title: t('statsList.total'),
                value: deploymentEnvironments?.length ?? 0,
              },
            ]}
            permission={defaultPermissionMap.deploymentEnvs}
            serverError={defaultServerErrorMap.deploymentEnvs}
          />
          <OverviewTile
            resourceType='GATEWAY'
            header={t('topologyTile.gateways')}
            data={formattedGateways.gateways}
            emptyState={{
              icon: images.noGatewayLargeIcon(),
              header: t('emptyState.gateway.header'),
              description: t('emptyState.gateway.description'),
              buttonText: t('emptyState.gateway.buttonText'),
              link: '/',
            }}
            addText={t('emptyState.gateway.buttonText')}
            loading={loadingGateways}
            statsList={gatewayStats}
            permission={defaultPermissionMap.gateways}
            serverError={defaultServerErrorMap.gateways}
          />
          <OverviewTile
            resourceType='CLOUD'
            header={t('topologyTile.clouds')}
            data={clouds}
            emptyState={{
              icon: images.NoCloudLarge(),
              header: t('emptyState.cloud.header'),
              description: t('emptyState.cloud.description'),
              buttonText: t('emptyState.cloud.buttonText'),
              link: '/',
            }}
            addText={t('emptyState.cloud.buttonText')}
            loading={loadingClouds}
            statsList={[
              {
                key: 'total',
                title: t('statsList.total'),
                value: clouds?.length ?? 0,
              },
            ]}
            permission={defaultPermissionMap.clouds}
            serverError={defaultServerErrorMap.clouds}
          />
          <OverviewTile
            resourceType='LOCATION'
            header={t('topologyTile.locations')}
            data={formattedLocations}
            emptyState={{
              icon: images.noLocations(),
              header: t('emptyState.location.header'),
              description: t('emptyState.location.description'),
              buttonText: t('emptyState.location.buttonText'),
              link: '/',
            }}
            addText={t('emptyState.location.buttonText')}
            loading={loadingLocations}
            statsList={[
              {
                key: 'total',
                title: t('statsList.total'),
                value: locations?.locationsData?.length ?? 0,
              },
            ]}
            permission={defaultPermissionMap.locations}
            serverError={defaultServerErrorMap.locations}
          />
        </div>
      </Tile>
    </div>
  );
};

export default LandingPage;
