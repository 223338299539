import { TooltipDefinition } from 'carbon-components-react';
import { useEffect, useState } from 'react';
import { VerticalDirection } from 'carbon-components-react/typings/shared';
import { splitStringToArray } from '../../lib/utils';

import './GenericTruncateString.scss';

const truncateDescription = (str: string, maxLength: number, limit: number) => {
  if (!str) return str;

  if (str.length > maxLength) {
    const stringArray = splitStringToArray(str);

    if (maxLength > limit) {
      // If the string contain non ASCII characters like chinese language etc, then length of that string will be greater than array length.
      const diff = str.length - stringArray.length;

      let strLeft = stringArray.slice(0, limit - diff / 2).join('');
      let strRight = stringArray.slice(-limit + diff / 2).join('');

      return strLeft + '...' + strRight;
    } else if (maxLength === limit) {
      return stringArray.slice(0, limit).join('') + '...';
    }
  }

  return str;
};

interface Props {
  str: string;
  limit?: number;
  maxLength?: number;
  tableView?: boolean;
  truncateEndOfString?: boolean;
  containerId?: string;
  tooltipDirection?: VerticalDirection;
}

const GenericTruncateString: React.FC<Props> = ({
  str,
  limit = 13,
  maxLength = 30,
  tableView = false,
  truncateEndOfString = false,
  containerId = '',
  tooltipDirection = 'bottom',
}) => {
  const [truncated, setTruncated] = useState(false);
  const strLength = str?.length >= maxLength;

  useEffect(() => {
    if (truncateEndOfString && containerId) {
      isTruncated();
    }
  }, []);

  const isTruncated = () => {
    const containerElem: any = document.getElementById(containerId);
    if (containerElem) {
      // Clone container element and make its content inline to get actual width of the string
      var clonedElem = containerElem.cloneNode(true);
      const innerElement = clonedElem.querySelector(
        '.generic-truncate-string '
      );
      clonedElem.innterHTML = str;
      clonedElem.style.display = 'inline';
      innerElement.style.display = 'inline';
      clonedElem.style.width = 'auto';
      clonedElem.style.visibility = 'hidden';
      containerElem.after(clonedElem);
      if (clonedElem?.offsetWidth > containerElem?.clientWidth) {
        setTruncated(true);
      }
      clonedElem.remove();
    }
  };

  return (
    <>
      {!truncateEndOfString &&
      ((strLength && !tableView) ||
        (strLength && tableView && !(str.indexOf(' ') >= 0))) ? (
        <TooltipDefinition
          tooltipText={str}
          direction={tooltipDirection}
          className='generic-truncate-string'
          align='center'
        >
          {truncateDescription(str, maxLength, limit)}
        </TooltipDefinition>
      ) : truncateEndOfString ? (
        truncated ? (
          <TooltipDefinition
            tooltipText={str}
            direction={tooltipDirection}
            className='generic-truncate-string'
            align='center'
          >
            <span className='generic-truncate-string truncate-last text-overflow-ellipsis'>
              {str}
            </span>
          </TooltipDefinition>
        ) : (
          <span className='generic-truncate-string truncate-last text-overflow-ellipsis'>
            {str}
          </span>
        )
      ) : (
        str
      )}
    </>
  );
};

export default GenericTruncateString;
