import React from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButton, TooltipIcon } from 'carbon-components-react';
import HealthStatus from '../../../../components/GatewayStatus/HealthStatus';
import ProceduralStatus from '../../../../components/GatewayStatus/ProceduralStatus';
import {
  HealthStatusType,
  ProceduralStatusType,
} from '../../../../components/GatewayStatus/config';
import GenericStatusField from '../../../../components/GenericStatusField/GenericStatusField';
import GenericTableWithFilters from '../../../../components/GenericTableWithFilters/GenericTableWithFilters';
import GenericTruncateString from '../../../../components/GenericTruncateString/GenericTruncateString';
import images from '../../../../images/images.js';
import dateUtils from '../../../../lib/dates';
import {
  AppliedFilter,
  DeploymentEnvironment,
  Gateway,
} from '../../../../models/master';
import useAnalytics from '../../../../lib/useAnalytics';
import { GatewayCustomData } from './RegisterRemoteConnection';
import analyticsData from '../../../../lib/analyticsEventData';
import { GatewayTypes } from '../../../../lib/enums';

interface GatewayTableRows {
  id: any;
  gateway_id: string;
  cloud_id: string | undefined;
  location_id: string | undefined;
  name:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  name__format: JSX.Element;
  type?: string;
  health_status: JSX.Element | undefined | string;
  version: JSX.Element | string | undefined;
  location: JSX.Element;
  updated_at: string;
  deployedIn: string | undefined;
  radioButton?: JSX.Element;
}

interface Props {
  filteredDataCallback: (data: any) => void;
  filtersSelected: any;
  filtersAppliedCallback: (data: any) => void;
  filters: any;
  rows: GatewayCustomData[] | null;
  data: GatewayCustomData[] | null;
  filteredDataSet: GatewayCustomData[] | null;
  elementCount: number;
  onRefresh: () => void;
  currentPageNumber: number;
  currentPageSize: number;
  onPageChange: (pageData: any) => void;
  sortRows(arg0: unknown, direction: string): void;
  visibilityFlag?: string;
  persistFilter?: boolean;
  error403Flag: boolean;
  remoteGatewaySelected: string;
  onRemoteGatewaySelected: (data: GatewayCustomData) => void;
  onClose: () => void;
  setSelectGatewayTearsheet: any;
  deploymentEnvsList: DeploymentEnvironment[] | null;
}

const GatewaysTable: React.FC<Props> = ({
  filteredDataCallback,
  filtersSelected,
  filtersAppliedCallback,
  filters,
  rows,
  data,
  filteredDataSet,
  elementCount,
  onRefresh,
  currentPageNumber,
  currentPageSize,
  onPageChange,
  sortRows,
  visibilityFlag,
  persistFilter,
  error403Flag,
  remoteGatewaySelected,
  onRemoteGatewaySelected,
  onClose,
  setSelectGatewayTearsheet,
  deploymentEnvsList,
}) => {
  const { t } = useTranslation('registerRemoteConnection');
  const { trackButtonClicked } = useAnalytics();

  const capitalizeFirstLetterInString = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const gatewayStatus = (
    status: string,
    name: string,
    version: string | number
  ) => {
    let statusWithIcon;
    statusWithIcon = (
      <span className='procedural__status'>
        <ProceduralStatus
          status={status as ProceduralStatusType}
          gatewayName={name}
          version={version}
        />
      </span>
    );
    return statusWithIcon;
  };

  const myheaders = [
    {
      key: 'radioButton',
      originalKey: 'radioButton',
      header: '',
    },
    {
      key: 'name__format',
      header: t('tableHeaders.name'),
      sort: true,
      originalKey: 'name',
    },
    {
      key: 'health_status',
      header: t('tableHeaders.health'),
      sort: true,
      originalKey: 'health_status',
      style: { minWidth: '9rem' },
    },
    {
      key: 'location',
      originalKey: 'location_name',
      header: t('tableHeaders.location'),
      sort: true,
    },
    {
      key: 'deployedIn',
      originalKey: 'deployedIn',
      header: t('tableHeaders.deployedIn'),
      sort: true,
    },
    {
      key: 'version',
      originalKey: 'version',
      header: t('tableHeaders.proceduralStatus'),
      sort: true,
    },
    {
      key: 'updated_at',
      originalKey: 'updated_at',
      header: t('tableHeaders.updated_at'),
      sort: true,
    },
  ];

  const emptyStateData = {
    icon: images.noGatewayLargeIcon(),
    notFoundIcon: images.NotFoundLarge(),
    emptyStateHeader: t('emptyState.emptyContainerHeader'),
    emptyStateDescription: t('emptyState.emptyContainerDescription'),
  };

  const renderRadioButton = (data: GatewayCustomData, checked: boolean) => {
    return (
      <RadioButton
        id={data.resource_id}
        labelText=''
        hideLabel
        checked={checked}
        onClick={() => onRemoteGatewaySelected(data)}
      />
    );
  };
  const selectRadioButton = (data: GatewayCustomData, checked: boolean) => {
    onRemoteGatewaySelected(data);
    return (
      <RadioButton
        id={data.resource_id}
        labelText=''
        hideLabel
        checked={checked}
      />
    );
  };

  const getDeplEnvName = (gateway: Gateway) => {
    const depEnv = deploymentEnvsList?.find(
      dep => dep.resource_id === gateway?.deployed_in_depl_env_id
    );
    return depEnv?.name ?? '';
  };

  const setRowsData = () => {
    let formattedRows: GatewayTableRows[] = [];
    if (error403Flag) return [];
    if (rows && rows.length === 0) return [];

    if (rows) {
      rows.map((row: GatewayCustomData, index) => {
        const deployedInVal = getDeplEnvName(row);
        formattedRows.push({
          id: row.resource_id + index,
          gateway_id: row.resource_id,
          cloud_id: row.cloud_id,
          location_id: row.location_id,
          name: !!row.name ? capitalizeFirstLetterInString(row.name) : '—',
          name__format: (
            <div className='gateway-name'>
              <div className='gateway-icon'>
                <TooltipIcon
                  children={
                    row.type === GatewayTypes.EDGE
                      ? images.EdgeGatewayIcon()
                      : images.WaypointGatewayIcon()
                  }
                  tooltipText={t(row.type) + t('gateway')}
                  direction={'top'}
                ></TooltipIcon>
              </div>
              <div className='table__gateway__name'>
                <GenericTruncateString str={row?.name} tableView={true} />
              </div>
            </div>
          ),
          health_status: !!row?.health_status ? (
            <HealthStatus
              status={row?.health_status as HealthStatusType}
              showLabel={true}
              viewEventsLink={false}
              gatewayName={row.name}
            />
          ) : (
            '—'
          ),
          deployedIn: deployedInVal
            ? deployedInVal?.concat(
                ' / ',
                row?.namespaceName || t('noPartitionAvailable')
              )
            : row?.namespaceName || '—',
          version: gatewayStatus(
            row.procedural_status,
            row.name,
            row.intended_version
          ),
          location: !row.locationPermission ? (
            <GenericStatusField status='notAuthorised'></GenericStatusField>
          ) : row.location_id ? (
            <GenericTruncateString
              str={row?.location_name || '—'}
              tableView={true}
            />
          ) : (
            <div>—</div>
          ),
          updated_at: !!row?.updated_at
            ? dateUtils.getUserFriendlyDate(row?.updated_at)
            : '—',
          radioButton: renderRadioButton(
            row,
            remoteGatewaySelected === row.resource_id
          ),
        });
        return 0;
      });
    } else return null;
    return formattedRows;
  };

  const handleRowClicked = (gwId: any) => {
    if (data) {
      const selRow = data.find(d => d?.resource_id === gwId?.slice(0, -1));

      if (selRow) {
        const chk =
          remoteGatewaySelected === ''
            ? true
            : remoteGatewaySelected === gwId?.slice(0, -1);
        selectRadioButton(selRow, chk);
      }
    }
  };
  return (
    <GenericTableWithFilters
      id='table-with-filter-panel'
      rows={setRowsData()}
      data={filteredDataSet}
      headers={myheaders}
      isSortable={true}
      totalElementsCount={elementCount ? elementCount : 0}
      fullData={data}
      onTableRefresh={() => onRefresh()}
      filteredDataCallback={(data: GatewayTableRows[]) =>
        filteredDataCallback(data)
      }
      selectedFiltersVal={filtersSelected as any}
      setFilterApplied={(data: AppliedFilter[]) => filtersAppliedCallback(data)}
      filters={filters}
      currentPageNumber={currentPageNumber}
      currentPageSize={currentPageSize}
      onPageChange={(pageData: any) => onPageChange(pageData)}
      emptyState={emptyStateData}
      sortRows={(data, sortDirection) => sortRows(data, sortDirection)}
      persistFilter
      render403Container={error403Flag}
      handleRowClick={handleRowClicked}
    />
  );
};

export default GatewaysTable;
