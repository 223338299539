import * as d3 from 'd3';
import forceBoundary from 'd3-force-boundary';
import { TFunction } from 'react-i18next';
import { getBrowserType, truncateWithoutTooltip } from '../../../lib/utils';
import {
  Connection,
  CustomLink,
  DeploymentEnvType,
  EdgeClassType,
  ExternalConnection,
  GatewayIcon,
  GatewayStatus,
  Location,
  LocationConnectionMap,
  Locations,
  LocationsNode,
  NestedLineCountMap,
  Node,
  Position,
  Resouce,
  ResouceType,
} from '../types';
import {
  getAppServiceAverageLatencyQuery,
  getLineConfig,
  getNodeConfig,
  getTunnelAverageLatencyQuery,
  GROUP_COLOR,
  SCALE_FACTOR,
} from './config';
import { getMetrics } from '../../../controllers/metricsApis';
import { DEFAULT_NETWORK_SEGMENT_ID } from '../../../lib/constants';

interface CallbackHandler {
  onNodeClick: (node: Location | Resouce) => void;
  onEdgeClick: (
    edge: d3.HierarchyLink<Location | Resouce>,
    edgeType: EdgeClassType
  ) => void;
  onNodeHover: (node: Location | Resouce, position: Position) => void;
  onNodeLeave: () => void;
  onCanvasClicked: () => void;
  onNodeDblClick: (node: Node<Location | Resouce>) => void;
}

const browser = getBrowserType();

class D3Graph {
  svg;
  data: Location[];
  gwConnections: any;
  idParent: any;
  root;
  simulation;
  linkContainer;
  edgeLabelContainer;
  nestedEdgeLabelContainer;
  nestedEdgeLabelCircleContainer;
  nodes: d3.Selection<
    SVGGElement,
    Node<Resouce | Location>,
    SVGSVGElement,
    unknown
  > | null;
  element: HTMLDivElement;
  valueline;
  groupIds;
  paths?: d3.Selection<SVGPathElement, string, SVGGElement, unknown>;
  resouceMap;
  t: TFunction<'topologyCanvasView', undefined>;
  callbackHandler: CallbackHandler;
  zoom: d3.ZoomBehavior<Element, unknown>;
  policies: Resouce[];
  namespaces: Resouce[];
  gateways: Resouce[];
  dataFlows: Resouce[];
  focusedEdge: string;
  focusedNode: string;
  selectedLayers = {
    policy: false,
    applicationConnections: false,
    gateways: false,
    gatewayConnections: false,
  };
  selectedExpansionLevel;
  enableFixNodesPosition;
  getewayLatency: { [key: string]: string };
  appServiceLatency: { [key: string]: string };
  externalConns: any[] = [];

  constructor(
    element: HTMLDivElement,
    data: Locations,
    t: TFunction<'topologyCanvasView', undefined>,
    callbackHandler: CallbackHandler
  ) {
    const { width, height } = element.getBoundingClientRect();

    this.svg = d3
      .select(element)
      .append('svg')
      .on('click', callbackHandler.onCanvasClicked)
      .attr('width', width)
      .attr('height', height)
      .style('margin-bottom', -5)
      .append('g');

    this.t = t;

    /*
      Note: Since the x, y, vx and vy properties are attaching to the data.connections and data.resouces attributes of data by D3,
      We need to use JSON parse and stringify to create a deep clone copy of data object. 
      Otherwise the force directed graph will render nodes in different position each time when you render it (ie open overlay).
    */
    this.data = JSON.parse(JSON.stringify(data.locations));

    this.resouceMap = data.resouceMap;
    this.callbackHandler = callbackHandler;

    this.policies = data.policies;
    this.namespaces = data.namespaces;
    this.gateways = data.gateways;
    this.dataFlows = data.dataFlows;

    this.focusedEdge = '';
    this.focusedNode = '';

    this.selectedExpansionLevel = 1;
    this.enableFixNodesPosition = true;

    this.getewayLatency = {};
    this.appServiceLatency = {};

    /**
     * The d3.hierarchy constructs a root node from our tree like data structure.
     * This tree like data structure helps us to easily collapse and expand nodes using simple DFS.
     */
    const formattedData = {
      name: 'root',
      children: this.data,
    };

    this.root = d3.hierarchy(formattedData);

    // Initially all deployment env in this locations will be in collapsed mode
    this.root.children?.forEach(data => this.collapseNode(data as any));

    const linkForce = d3
      .forceLink()
      .id((d: any) => d.data.resource_id) // The unqiue id of each resources
      .distance((d: any) => {
        // To add more distance for edges connecting 2 locations.
        if (d?.type === 'GATEWAY') {
          return 350;
        }
        if (d?.type === 'GATEWAY_HITSPOT') {
          return 350;
        }
        if (d?.type === 'POLICY') {
          return 350;
        }
        if (d?.type === 'DATAFLOW') {
          return 350;
        }
        if (d?.type === 'DATAFLOW_HITSPOT') {
          return 350;
        }
        if (d?.type === 'NESTED') {
          return 300;
        }
        if (d?.type === 'DATAFLOW-NESTED') {
          return 300;
        }
        if (d?.type === 'POLICY-NESTED') {
          return 300;
        }
        if (d?.type === 'GATEWAY-NESTED') {
          return 300;
        }

        return 40;
      })
      .strength(0.2)
      .iterations(2);

    /**
     * d3.forceSimulation help us to create force directed graph.
     * The reason why we go with force directed graph is because the simulation will place the Nodes in some random position
     * based on the amount of force we applies so we don't need to manually pass the x and y coordinates to place the Nodes.
     */
    this.simulation = d3
      .forceSimulation()
      .force('charge', d3.forceManyBody().strength(-600))
      .force('link', linkForce)
      .force(
        'collide',
        d3.forceCollide().radius(50).strength(0.5).iterations(2)
      )
      .force('boundary', forceBoundary(0, 0, width, height))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('x', d3.forceX(0).strength(0.01))
      .force('y', d3.forceY(0).strength(0.01))
      .alphaDecay(0.005);

    this.groupIds = data.locationIds;
    this.valueline = d3
      .line()
      .x(d => d[0])
      .y(d => d[1])
      .curve(d3.curveCatmullRomClosed); // Note: Other curve types are 'curveBasisClosed', 'curveCardinalClosed', 'curveLinearClosed'

    this.appendGroup();

    this.linkContainer = this.svg.append('g').attr('class', 'links');
    this.edgeLabelContainer = this.svg.append('g').attr('class', 'edge-labels');
    this.nestedEdgeLabelCircleContainer = this.svg
      .append('g')
      .attr('class', 'nested-edge-labels-circle');
    this.nestedEdgeLabelContainer = this.svg
      .append('g')
      .attr('class', 'nested-edge-labels');

    this.nodes = null;
    this.element = element;

    this.zoom = d3
      .zoom()
      .scaleExtent([0.6, 2.5])
      .on('zoom', this.handleZoom.bind(this));

    this.renderNodes();
  }

  handleD3LinkDistance(data: any) {
    this.enableFixNodesPosition = false;

    const traverse = (
      node: d3.HierarchyNode<Resouce> | d3.HierarchyNode<LocationsNode> | any
    ) => {
      if (node) {
        node.fx = null;
        node.fy = null;
      }

      if (node.children) node.children.forEach(traverse);
    };

    traverse(this.root);
    this.renderNodes();

    const linkForce = d3
      .forceLink()
      .id((d: any) => d.data.resource_id) // The unqiue id of each resources
      .distance((d: any) => {
        // To add more distance for edges connecting 2 locations.

        if (d?.type === 'GATEWAY') {
          return data?.gwConn;
        }
        if (d?.type === 'POLICY') {
          return data?.polConn;
        }
        if (d?.type === 'DATAFLOW') {
          return data?.appConn;
        }
        if (d?.type === 'NESTED') {
          return data?.nestedConn;
        }
        if (d?.type === 'DATAFLOW-NESTED') {
          return data?.nestedConn;
        }
        if (d?.type === 'POLICY-NESTED') {
          return data?.nestedConn;
        }
        if (d?.type === 'GATEWAY-NESTED') {
          return data?.nestedConn;
        }

        return data?.relConn;
      })
      .strength(0.2)
      .iterations(2);

    const { width, height } = this.element.getBoundingClientRect();
    this.simulation.force('boundary', forceBoundary(0, 0, width, height));

    this.simulation.force('link', linkForce);

    this.renderNodes();
  }

  /**
   * This method is responsible for rendering the D3 Nodes in the canvas using d3.forceSimulation.
   */
  renderNodes() {
    let links: d3.Selection<
      SVGLineElement,
      d3.HierarchyLink<Location | Resouce>,
      SVGGElement,
      unknown
    >;
    let edgeLabels: d3.Selection<
      SVGTextElement,
      CustomLink<LocationsNode | Resouce>,
      SVGGElement,
      unknown
    >;
    let nestedEdgeLabels: d3.Selection<
      SVGTextElement,
      CustomLink<LocationsNode | Resouce>,
      SVGGElement,
      unknown
    >;
    let nestedEdgeLabelsCircle: d3.Selection<
      SVGCircleElement,
      CustomLink<LocationsNode | Resouce>,
      SVGGElement,
      unknown
    >;
    let nodes: d3.Selection<
      SVGGElement,
      Node<Location | Resouce>,
      SVGSVGElement,
      unknown
    >;
    const { width, height } = this.element.getBoundingClientRect();

    const dataNodes = this.flattenNodes();

    const connections: CustomLink<LocationsNode | Resouce>[] =
      this.filterConnections(this.root.links() as any);

    let policyConnections: Connection[] = [];
    if (this.selectedLayers.policy) {
      policyConnections = this.getPolicyConnections();

      for (const policyConnection of policyConnections) {
        if (
          !this.externalConns.find(
            conn =>
              JSON.stringify(conn) ===
              JSON.stringify({
                source: policyConnection.start as any,
                target: policyConnection.end as any,
                type: 'POLICY',
              })
          )
        ) {
          this.externalConns.push({
            source: policyConnection.start as any,
            target: policyConnection.end as any,
            type: 'POLICY',
          });
        }
        if (
          this.checkNodeVisible(policyConnection.start) &&
          this.checkNodeVisible(policyConnection.end)
        ) {
          connections.push({
            source: policyConnection.start as any,
            target: policyConnection.end as any,
            type: 'POLICY',
          });
        }
      }
    } else {
      if (this.externalConns) {
        const filteredConns = this.externalConns.filter(
          conn => conn.type !== 'POLICY'
        );
        this.externalConns = filteredConns;
      }
    }

    let applicationConnections: Connection[] = [];

    if (this.selectedLayers.applicationConnections) {
      applicationConnections = this.getApplicationConnections();

      for (const applicationConnection of applicationConnections) {
        if (
          !this.externalConns.find(
            conn =>
              JSON.stringify(conn) ===
              JSON.stringify({
                source: applicationConnection.start as any,
                target: applicationConnection.end as any,
                type: 'DATAFLOW',
              })
          )
        )
          this.externalConns.push({
            source: applicationConnection.start as any,
            target: applicationConnection.end as any,
            type: 'DATAFLOW',
          });
        if (
          this.checkNodeVisible(applicationConnection.start) &&
          this.checkNodeVisible(applicationConnection.end)
        ) {
          connections.push({
            source: applicationConnection.start as any,
            target: applicationConnection.end as any,
            type: 'DATAFLOW_HITSPOT',
          });
          connections.push({
            source: applicationConnection.start as any,
            target: applicationConnection.end as any,
            type: 'DATAFLOW',
          });
        }
      }
    } else {
      if (this.externalConns) {
        const filteredConns = this.externalConns.filter(
          conn => conn.type !== 'DATAFLOW'
        );
        this.externalConns = filteredConns;
      }
    }

    if (this.selectedLayers.gatewayConnections) {
      const gatewayConnections = this.getGatewayConnections();

      for (const gatewayConnection of gatewayConnections) {
        if (
          !this.externalConns.find(
            conn =>
              JSON.stringify(conn) ===
              JSON.stringify({
                source: gatewayConnection.start as any,
                target: gatewayConnection.end as any,
                type: 'GATEWAY',
              })
          )
        ) {
          this.externalConns.push({
            source: gatewayConnection.start as any,
            target: gatewayConnection.end as any,
            type: 'GATEWAY',
          });
        }
        if (
          this.checkNodeVisible(gatewayConnection.start) &&
          this.checkNodeVisible(gatewayConnection.end)
        ) {
          connections.push({
            source: gatewayConnection.start as any,
            target: gatewayConnection.end as any,
            type: 'GATEWAY_HITSPOT',
          });
          connections.push({
            source: gatewayConnection.start as any,
            target: gatewayConnection.end as any,
            type: 'GATEWAY',
          });
        }
      }
    } else {
      if (this.externalConns) {
        const filteredConns = this.externalConns.filter(
          conn => conn.type !== 'GATEWAY'
        );
        this.externalConns = filteredConns;
      }
    }

    if (
      this.selectedLayers.gatewayConnections ||
      this.selectedLayers.applicationConnections ||
      this.selectedLayers.policy
    ) {
      const dataFlowConnections = this.getExternalConnections();

      for (const dataFlowConnection of dataFlowConnections) {
        let flag = false;
        for (const extConn of this.externalConns) {
          if (
            dataFlowConnection?.type !== '' &&
            ((extConn.source === dataFlowConnection.start &&
              extConn.target !== dataFlowConnection.end) ||
              (extConn.source !== dataFlowConnection.end &&
                extConn.target === dataFlowConnection.start) ||
              (extConn.target !== dataFlowConnection.start &&
                extConn.source === dataFlowConnection.end) ||
              (extConn.target === dataFlowConnection.end &&
                extConn.source !== dataFlowConnection.start))
          ) {
            flag = true;
            connections.push({
              source: dataFlowConnection.start as any,
              target: dataFlowConnection.end as any,
              type: ((dataFlowConnection.type as any) + '-NESTED') as any,
            });
          }
        }
        if (flag === false) {
          connections.push({
            source: dataFlowConnection.start as any,
            target: dataFlowConnection.end as any,
            type:
              dataFlowConnection?.type !== ''
                ? (dataFlowConnection.type as any)
                : 'NESTED',
          });
        }
      }
    }

    const nestedConnNodes = connections.filter(conn => conn.type === 'NESTED');

    const nestedLineCountMap: NestedLineCountMap = {};

    for (const nestedConnNode of nestedConnNodes) {
      const key = [nestedConnNode?.source, nestedConnNode?.target]
        .sort()
        .toString();
      nestedLineCountMap[key] = {
        count: connections?.filter(
          conn =>
            conn?.source === nestedConnNode?.source &&
            conn?.target === nestedConnNode?.target
        )?.length,
      };
    }

    const outerConnections = connections?.map(connection => {
      return {
        ...connection,
        type: 'OUTER',
      };
    });

    links = this.linkContainer
      .selectAll('line')
      .data([...outerConnections, ...connections]) as any;

    // Remove old links
    links.exit().remove();

    const linksEnter = links
      .enter()
      .append('line')
      .on('mouseover', (e, d) => {
        e.target.classList.add('hover');
      })
      .on('mouseout', (e, d) => {
        e.target.classList.remove('hover');
      });

    links = linksEnter.merge(links);

    // Append dynamic edge class based on the edge type and relationship
    links
      .attr('class', (d: any) => {
        if (d.type === 'OUTER') {
          return 'transparent-line';
        }
        if (d.type === 'DATAFLOW_HITSPOT') {
          return 'connection-line-hitSpot';
        }
        if (d.type === 'GATEWAY_HITSPOT') {
          return 'gateway-line-hitSpot';
        }

        return getLineConfig(d, this.focusedEdge);
      })

      .on('click', (e, d: any) => {
        // To prevent moving of topology nodes when we click edge after expanding the nodes via node expansion option
        if (!this.enableFixNodesPosition) {
          this.enableFixNodesPosition = true;
          this.fixNodes();
        }

        // Disable Sidepanel if either one of node is disabled
        if (
          (d?.source?.data?.label &&
            d?.source?.data?.label === 'Not Authorized') ||
          (d?.target?.data?.label &&
            d?.target?.data?.label === 'Not Authorized')
        ) {
          return;
        }

        e.stopPropagation();
        const className =
          e.target?.classList[0] === 'transparent-line'
            ? 'relation-line'
            : e.target?.classList[0];
        this.callbackHandler.onEdgeClick(d as any, className);
      });

    const nestedConnections = connections
      ? connections.filter(conn => conn?.type?.includes('NESTED'))
      : [];
    const nonNestedConnections = connections
      ? connections.filter(conn => !conn?.type?.includes('NESTED'))
      : [];

    // To render latency labels in edges

    nestedEdgeLabels = this.nestedEdgeLabelContainer
      .selectAll('text')
      .data([...outerConnections, ...nestedConnections]) as any;

    nestedEdgeLabelsCircle = this.nestedEdgeLabelCircleContainer
      .selectAll('circle')
      .data([...nestedConnections]) as any;

    edgeLabels = this.edgeLabelContainer
      .selectAll('text')
      .data([...nonNestedConnections]) as any;

    // Remove old edge labels
    nestedEdgeLabels.exit().remove();
    nestedEdgeLabelsCircle.exit().remove();
    edgeLabels.exit().remove();

    const nestedEdgeLabelCircleEnter = nestedEdgeLabelsCircle
      .enter()
      .append('circle');
    const nestedEdgeLabelEnter = nestedEdgeLabels.enter().append('text');
    const edgeLabelEnter = edgeLabels.enter().append('text');

    nestedEdgeLabelsCircle = nestedEdgeLabelCircleEnter.merge(
      nestedEdgeLabelsCircle
    );
    nestedEdgeLabels = nestedEdgeLabelEnter.merge(nestedEdgeLabels);
    edgeLabels = edgeLabelEnter.merge(edgeLabels);

    nestedEdgeLabels.text(d => {
      if (d?.type === 'DATAFLOW-NESTED') {
        const key = [d?.source, d?.target].sort().toString();
        return nestedLineCountMap[key]?.count > 0
          ? nestedLineCountMap[key]?.count
          : '1';
      }
      if (d?.type === 'POLICY-NESTED') {
        const key = [d?.source, d?.target].sort().toString();
        return nestedLineCountMap[key]?.count > 0
          ? nestedLineCountMap[key]?.count
          : '1';
      }
      if (d?.type === 'GATEWAY-NESTED') {
        const key = [d?.source, d?.target].sort().toString();
        return nestedLineCountMap[key]?.count > 0
          ? nestedLineCountMap[key]?.count
          : '1';
      }
      if (d?.type === 'NESTED') {
        const key = [d?.source, d?.target].sort().toString();
        return nestedLineCountMap[key]?.count;
      }

      return '';
    });

    edgeLabels.text(d => {
      if (d?.type === 'GATEWAY') {
        const key = `${d.source}-${d.target}`;
        if (this.getewayLatency[key]) {
          return `${this.getewayLatency[key]}${this.t('ms')}`;
        } else {
          // call API to fetch and store it
          this.getGatewayLatency((d as any).source, (d as any).target);
        }
      }

      if (d?.type === 'DATAFLOW') {
        const key = `${d.source}-${d.target}`;
        if (key.includes('depl') && key.includes('serviceD')) {
          if (this.appServiceLatency[key]) {
            // To prevent infinite loop of API calls when we get NaN from API
            if (this.appServiceLatency[key] === '-') return '';

            return `${this.appServiceLatency[key]}${this.t('ms')}`;
          } else {
            // call API to fetch and store it
            this.getAppServiceLatency((d as any).source, (d as any).target);
          }
        }
      }

      return '';
    });

    nodes = this.svg
      .selectAll('.nodes')
      .data(dataNodes, (d: any) => d.data.resource_id) as any;

    // Remove all nodes
    nodes.exit().remove();

    let timeout: NodeJS.Timeout;

    const nodeEnter = nodes
      .enter()
      .append('g')
      .attr('class', d => {
        let disabled = '';
        if (d?.data?.label && d?.data?.label === 'Not Authorized') {
          disabled = 'disabled';
        }

        let unmanaged = '';
        if (d?.data?.unmanaged) {
          unmanaged = 'unmanaged';
        }

        return `nodes ${disabled} ${unmanaged}`;
      })
      .on('click', (e, node) => {
        // Disable Sidepanel if node is disabled
        if (node?.data?.label && node?.data?.label === 'Not Authorized') {
          return;
        }

        clearTimeout(timeout);
        // Note: In D3 we need to add a timer to distingish between single and double clicks.
        timeout = setTimeout(() => {
          e.stopPropagation();
          this.callbackHandler.onNodeClick(node.data);
        }, 300);
      })

      .on('dblclick', (e, node) => {
        e.stopPropagation();

        this.simulation
          .force(
            'boundary',
            forceBoundary(
              Math.max(node.x - 100, 0),
              Math.max(node.y - 100, 0),
              Math.min(node.x + 100, width),
              Math.min(node.y + 100, height)
            )
          )
          .force('center', null);

        if (e.shiftKey) {
          this.expandChildNodes(node);
        } else {
          this.toggleNode(node);
        }

        this.callbackHandler.onNodeDblClick(node);
      })
      .on('mouseover', (e, node) => {
        nodes.attr('class', d => {
          let disabled = '';
          if (d?.data?.label && d?.data?.label === 'Not Authorized') {
            disabled = 'disabled';
          }

          let unmanaged = '';
          if (d?.data?.unmanaged) {
            unmanaged = 'unmanaged';
          }

          if (node?.data?.resource_id === d?.data?.resource_id) {
            return `nodes hover ${disabled} ${unmanaged}`;
          }

          return `nodes ${disabled}  ${unmanaged}`;
        });

        clearTimeout(timeout);
        timeout = setTimeout(() => {
          e.stopPropagation();
          this.callbackHandler.onNodeHover(node.data, {
            x: browser === 'Firefox' ? node.x : e.layerX,
            y: browser === 'Firefox' ? node.y : e.layerY,
          });
        }, 1000);
      })
      .on('mouseout', (e, node) => {
        nodes.attr('class', d => {
          let disabled = '';
          if (d?.data?.label && d?.data?.label === 'Not Authorized') {
            disabled = 'disabled';
          }

          let unmanaged = '';
          if (d?.data?.unmanaged) {
            unmanaged = 'unmanaged';
          }

          return `nodes ${disabled} ${unmanaged}`;
        });

        clearTimeout(timeout);
        timeout = setTimeout(() => {
          e.stopPropagation();
          this.callbackHandler.onNodeLeave();
        }, 1000);
      });

    nodes = nodeEnter.merge(nodes);

    this.nodes = nodes;

    // Append focused class to outer circle
    nodeEnter
      .append('circle')
      .attr('r', d => {
        const nodeConfig = getNodeConfig(d.data._type);
        return nodeConfig?.radius + 5 ?? 21;
      })
      .attr('class', 'outer-circle');
    // When the this.focusedNode instance value changes,
    // since the actual dataNodes values didn't change we need to manually select all outer circle then dynamically append focus class
    const focusedNodes = this.svg.selectAll('.nodes circle.outer-circle');
    focusedNodes.attr('class', (d: any) => {
      return d.data.uniqueId === this.focusedNode
        ? 'outer-circle focused-circle'
        : 'outer-circle hidden-circle';
    });

    // Append outer circle inside each nodes
    nodeEnter
      .append('circle')
      .attr('r', d => {
        const nodeConfig = getNodeConfig(d.data._type);
        return nodeConfig?.radius ?? 16;
      })
      .attr('class', d => {
        const nodeConfig = getNodeConfig(d.data._type, d.data);
        return nodeConfig?.class ?? '';
      })
      .attr('id', d => d.data.resource_id);

    // Append inner svg icon inside each nodes
    nodeEnter
      .append('svg:image')
      .attr('xlink:href', d => {
        const nodeConfig = getNodeConfig(d.data._type);

        // In the case of deployment env, we need to render seperate icons for cluster, node and vpc
        if (d.data._type === 'deployment_env' && d.data.intrinsicType) {
          const icon = nodeConfig.icon as DeploymentEnvType;
          return (icon as any)[d.data.intrinsicType] ?? '';
        }

        // In the case of gateway, we need to render seperate icons for edge or gateway
        if (d.data._type === 'gateway') {
          const icon = nodeConfig.icon as GatewayIcon;
          // When user doesn't contain gateway permission, we will not get type attribute.
          // So we need to default it to edge so that atleast an icon is rendered in this scenario.
          return (icon as any)[d?.data?.type ?? 'edge'] ?? '';
        }

        return nodeConfig?.icon ?? '';
      })
      .attr('height', d => {
        const nodeConfig = getNodeConfig(d.data._type);
        return nodeConfig?.height ?? '20';
      })
      .attr('width', d => {
        const nodeConfig = getNodeConfig(d.data._type);
        return nodeConfig?.width ?? '20';
      })
      .attr('x', d => {
        const nodeConfig = getNodeConfig(d.data._type);
        return nodeConfig?.x ?? -10;
      })
      .attr('y', d => {
        const nodeConfig = getNodeConfig(d.data._type);
        return nodeConfig?.y ?? -10;
      });

    // Append gateway status icon if needed
    nodeEnter
      .append('svg:image')
      .attr('xlink:href', d => {
        const nodeConfig = getNodeConfig(d?.data?._type);

        let gwType = d?.data?._type;
        let gwStatus = d?.data?.procedural_status;
        let gwHealthStatus = d?.data?.health_status;

        // In the case of gateway status, we need to render seperate icons for errored, deploying or operational
        if (
          (gwType === 'gateway' &&
            gwStatus === 'deploying' &&
            gwHealthStatus === 'operational') ||
          (gwType === 'gateway' &&
            gwStatus === 'errored' &&
            gwHealthStatus === 'operational')
        ) {
          const icon = nodeConfig.icon as GatewayStatus;
          return (icon as any)[gwStatus] ?? '';
        } else if (
          gwType === 'gateway' &&
          gwStatus !== 'errored' &&
          gwHealthStatus === 'critical'
        ) {
          const icon = nodeConfig.icon as GatewayStatus;
          return (icon as any)[gwHealthStatus] ?? '';
        }
      })
      .attr('class', d => {
        return 'gateway-type-svg';
      })
      .attr('height', d => {
        return '12';
      })
      .attr('width', d => {
        return '12';
      })
      .attr('x', d => {
        return 6;
      })
      .attr('y', d => {
        return -16;
      });

    // Append node labels below each nodes
    nodeEnter
      .append('text')
      .attr('class', 'label')
      .attr('dx', 0)
      .attr('dy', d => {
        const nodeConfig = getNodeConfig(d.data._type);
        return nodeConfig?.labelDy ?? 35;
      })
      .text(d => {
        const nodeConfig = getNodeConfig(d.data._type);
        const key = nodeConfig?.labelKey ?? 'name';
        const label = truncateWithoutTooltip((d.data as any)[key], 28, 5);
        return label;
      });

    // To enable node drag
    nodes.call(
      d3
        .drag<SVGGElement, Node<Location | Resouce>>()
        .on('start', this.dragstarted.bind(this))
        .on('drag', this.dragged.bind(this))
        .on('end', (event, node) => {
          this.dragended(event, node);
        })
    );

    this.simulation.nodes(dataNodes as any);
    this.simulation.alpha(1).alphaDecay(0.05).restart();
    this.simulation.on('tick', () => {
      this.updatePosition(
        links,
        nodes,
        edgeLabels,
        nestedEdgeLabels,
        nestedEdgeLabelsCircle
      );
      this.updateGroups();
    });
    this.simulation.on('end', () => this.fixNodes());
    (this.simulation as any).force('link')?.links(connections);

    return dataNodes;
  }

  dragstarted(
    event: d3.D3DragEvent<Element, never, never>,
    node: Node<Location | Resouce>
  ) {
    this.enableFixNodesPosition = false;
    if (!event.active) this.simulation.alphaTarget(0.3).restart();
    node.fx = node.x;
    node.fy = node.y;

    if (event.sourceEvent.shiftKey) {
      this.enableFixNodesPosition = true;
      this.fixNodes();
    } else {
      this.removeFixedNodesPosition();
    }
  }

  dragged(
    event: d3.D3DragEvent<Element, never, never>,
    node: Node<Location | Resouce>
  ) {
    node.fx = event.x;
    node.fy = event.y;
    this.fixNode(node);
  }

  dragended(
    event: d3.D3DragEvent<Element, never, never>,
    node: Node<Location | Resouce>
  ) {
    if (!event.active) this.simulation.alphaTarget(0);
    node.fx = event.x;
    node.fy = event.y;
  }

  /**
   * To prevent the edge case where multiple nodes may be clicked before the stimulation ends and nodes got places in extreme positions.
   */
  fixNodes() {
    if (this.nodes && this.enableFixNodesPosition) {
      this.nodes.each(node => {
        node.fx = node.x;
        node.fy = node.y;
      });
    }
  }

  /**
   * This method help us to fix the position of a node that is dragged so that next time when user toggle nodes, they will be in same position.
   * This is done to prevent a bug in which sibling nodes are moving along with dragged node.
   */
  fixNode(currentNode: Node<Location | Resouce>) {
    if (this.nodes && this.enableFixNodesPosition) {
      this.nodes.each(node => {
        if (currentNode !== node) {
          node.fx = node.x;
          node.fy = node.y;
        }
      });
    }
  }

  updatePosition(
    links: d3.Selection<SVGLineElement, any, SVGGElement, unknown>,
    nodes: d3.Selection<SVGGElement, any, SVGGElement, unknown>,
    edgeLabels: d3.Selection<SVGTextElement, any, SVGGElement, unknown>,
    nestedEdgeLabels: d3.Selection<SVGTextElement, any, SVGGElement, unknown>,
    nestedEdgeLabelsCircle: d3.Selection<
      SVGCircleElement,
      any,
      SVGGElement,
      unknown
    >
  ) {
    nodes.attr('transform', d => 'translate(' + d?.x + ',' + d?.y + ')');

    links
      .attr('x1', d => d?.source.x)
      .attr('y1', d => d?.source.y)
      .attr('x2', d => d?.target.x)
      .attr('y2', d => d?.target.y);

    edgeLabels
      .attr('x', d =>
        d?.source.x ? d?.source.x + (d?.target.x - d?.source.x) / 2 : 0
      )
      .attr('y', d =>
        d?.source.y ? d?.source.y + (d?.target.y - d?.source.y) / 2 : 0
      );

    nestedEdgeLabels
      .attr('x', d =>
        d?.source.x ? d?.source.x + (d?.target.x - d?.source.x) / 2 : 0
      )
      .attr('y', d =>
        d?.source.y ? d?.source.y + (d?.target.y - d?.source.y) / 2 + 4 : 0
      );

    nestedEdgeLabelsCircle
      .attr('cx', (d: { source: { x: number }; target: { x: number } }) =>
        d?.source.x ? d?.source.x + (d?.target.x - d?.source.x) / 2 : 0
      )
      .attr('cy', (d: { source: { y: number }; target: { y: number } }) =>
        d?.source.y ? d?.source.y + (d?.target.y - d?.source.y) / 2 : 0
      )
      .attr('r', 9);
  }

  /**
   * Append the parent element responsible for generating the polygon convex hull to group all nodes under a location.
   */
  appendGroup() {
    const groups = this.svg.append('g').attr('class', 'groups');

    this.paths = groups
      .selectAll('.path_placeholder')
      .data(this.groupIds)
      .enter()
      .append('g')
      .attr('class', 'path_placeholder')
      .append('path')
      .attr('stroke', GROUP_COLOR)
      .attr('fill', GROUP_COLOR)
      .attr('opacity', 0);

    this.paths.transition().duration(2000).attr('opacity', 1);
  }

  polygonGenerator(groupId: string) {
    if (this.nodes) {
      const nodeCoords = this.nodes
        .filter(d => d.data.location_id === groupId)
        .data()
        .map(d => [d.x, d.y]);

      // We need atleast 3 nodes to form a convex hull.
      // Note: Sometimes a location may have only one deployment env.
      if (nodeCoords.length === 2) {
        nodeCoords.push([nodeCoords[0][0] + 10, nodeCoords[1][1] + 10]);
        nodeCoords.push([nodeCoords[0][0] - 10, nodeCoords[1][1] - 10]);
      }

      return d3.polygonHull(nodeCoords as any);
    }
  }

  /**
   * To adjust the polygon convex hull position on each stimulation ticks.
   */
  updateGroups() {
    let centroid: [number, number];

    this.groupIds.forEach(groupId => {
      if (this.paths) {
        const path = this.paths
          .filter(d => d === groupId)
          .attr('transform', 'scale(1) translate(0,0)')
          .attr('d', d => {
            const polygon = this.polygonGenerator(d);

            if (polygon) {
              centroid = d3.polygonCentroid(polygon as any);
            }

            // Note: To scale the shape properly around its points: move the 'g' element to the centroid point, translate
            // all the path around the center of the 'g' and then we can scale the 'g' element properly
            if (polygon && centroid) {
              return this.valueline(
                polygon &&
                  polygon.map(point => [
                    point[0] - centroid[0],
                    point[1] - centroid[1],
                  ])
              );
            }

            return null;
          });

        if (centroid) {
          d3.select(path?.node()?.parentNode as any).attr(
            'transform',
            'translate(' +
              centroid[0] +
              ',' +
              centroid[1] +
              ') scale(' +
              SCALE_FACTOR +
              ')'
          );
        }
      }
    });
  }

  /**
   * This method is responsible for fetching all the open nodes in the data.
   * If the node has children then it is not collapsed so we recursively scanned the children using DFS and return them too.
   * If the node.children is null then that means the children are in collapsed state.
   */
  flattenNodes() {
    const nodes: Node<Resouce>[] = [];

    const traverse = (
      node: d3.HierarchyNode<Resouce> | d3.HierarchyNode<LocationsNode>
    ) => {
      if (node.children) node.children.forEach(traverse);

      if (node.parent) {
        if (
          (node as d3.HierarchyNode<Resouce>)?.data?.entityType === 'gateway' &&
          !this.selectedLayers.gateways
        ) {
          return;
        }

        nodes.push(node as any);
      }
    };

    traverse(this.root);

    this.fixNodes();

    return nodes;
  }

  /**
   * This method is responsible for checking whether a node with given resouceId is visible in topology or not.
   */
  checkNodeVisible(resouceId: string) {
    let isVisible = false;
    const traverse = (node: d3.HierarchyNode<Resouce>) => {
      if (isVisible) {
        return;
      }

      if (node?.data?.uniqueId === resouceId) {
        isVisible = true;
        return;
      }

      if (node?.children) node?.children?.forEach(traverse);
    };

    traverse(this.root as any);

    return isVisible;
  }

  /**
   * This method is responsible for checking whether an edge with given resouceId is visible in topology or not.
   */
  checkEdgeVisible(edgeId: string) {
    let isVisible = false;
    const traverse = (node: d3.HierarchyNode<Resouce>) => {
      if (isVisible) {
        return;
      }

      if (edgeId.includes(node.data.uniqueId)) {
        isVisible = true;
        return;
      }

      if (node.children) node.children.forEach(traverse);
    };

    traverse(this.root as any);

    return isVisible;
  }

  /**
   * This method filter out all the connections generated by d3.hierarchy with no parent.
   * This is done to avoid creating d3 force line connection from Deployment Env to Locations as locations are outside d3 overlay.
   */
  filterConnections(connections: d3.HierarchyLink<LocationsNode | Resouce>[]) {
    let validConns;
    if (!this.selectedLayers.gateways) {
      validConns = connections.filter(
        conn =>
          (conn as d3.HierarchyLink<Resouce>).target?.data?.entityType !==
          'gateway'
      );
    } else {
      validConns = connections;
    }
    const filteredConnections = validConns.filter(
      connection => connection.source?.parent !== null
    );

    return filteredConnections;
  }

  /**
   * To recursively scan and collapse all the nodes and its children by setting node.children to null.
   */
  collapseNode(data: Node<LocationsNode | Resouce>) {
    this.enableFixNodesPosition = true;
    // To handle scenario in which some nodes get into children and some into _children when we use "Expand to" option.
    if (data.children && data._children) {
      data.children = [...data.children, ...data._children];
    }

    if (data && data.children) {
      data._children = data.children;
      data._children?.forEach(d => this.collapseNode(d as any));
      data.children = null;
    }
  }

  /**
   * This method is responsible for recursively toggling the nodes ie opening and collapsing the children of a node.
   * If node.children is set to null, then it will be in collapsed state.
   * If node.children is not null, then it will be in opened state.
   */
  toggleNode(data: Node<Location | Resouce>) {
    // To handle scenario in which some nodes get into children and some into _children when we use "Expand to" option.
    this.enableFixNodesPosition = true;
    if (data.children && data._children) {
      data.children = [...data.children, ...data._children];
    }

    if (data.children) {
      data._children = data.children;
      data._children?.forEach(d => this.collapseNode(d as any));
      data.children = null;
    } else {
      data.children = data._children;
      data._children = null;
    }

    this.fixNodes();

    this.renderNodes();
  }

  /**
   * To expand all the children of a specific node.
   * If node.children is set to null, then it will be in collapsed state.
   * If node.children is not null, then it will be in opened state.
   */
  expandChildNodes(data: Node<Location | Resouce>) {
    this.enableFixNodesPosition = true;
    const traverse = (data: Node<Resouce>) => {
      data.fx = null;
      data.fy = null;

      if (data._children) {
        data.children = data._children;
        data.children?.forEach(d => traverse(d as any));
        data._children = null;
      }
    };

    // To handle scenario in which some nodes get into children and some into _children when we use "Expand to" option.
    if (data.children && data._children) {
      data._children = [...data._children, ...data.children];
    }

    if (data._children) {
      data.fx = null;
      data.fy = null;

      data.children = data._children;
      data.children?.forEach(d => traverse(d as any));
      data._children = null;
    }

    this.fixNodes();

    this.renderNodes();
  }

  /**
   * Resolve the visibility of node based on its node type and its selection in "Expand To" session.
   */
  resolveVisibility(type: ResouceType) {
    switch (type) {
      case 'deployment_env': {
        return this.selectedExpansionLevel >= 2;
      }
      case 'gateway': {
        return this.selectedExpansionLevel >= 2;
      }
      case 'partition': {
        return this.selectedExpansionLevel >= 3;
      }
      case 'application': {
        return this.selectedExpansionLevel >= 4;
      }
      case 'service': {
        return this.selectedExpansionLevel >= 5;
      }
      default: {
        return false;
      }
    }
  }

  /**
   * To expand and collapse nodes based on the selection in "Expand To" session.
   * 1st we modified the children and _children property of each nodes of root.
   * The visible nodes are placed in children and hidden nodes are in _children.
   * Once the root node updated we call the renderNodes method to rerender everything.
   */
  toggleNodes() {
    this.enableFixNodesPosition = true;
    const traverse = (data: Node<Resouce>) => {
      data.fx = null;
      data.fy = null;

      let visibleChildren: d3.HierarchyNode<Resouce>[] = [];
      let hiddenChildren: d3.HierarchyNode<Resouce>[] = [];

      if (Array.isArray(data._children)) {
        const filteredVisibleChildren = data._children.filter(node =>
          this.resolveVisibility(node.data?._type)
        );
        const filteredHiddenChildren = data._children.filter(
          node => !this.resolveVisibility(node.data?._type)
        );

        visibleChildren = [...visibleChildren, ...filteredVisibleChildren];
        hiddenChildren = [...hiddenChildren, ...filteredHiddenChildren];
      }

      if (Array.isArray(data.children)) {
        const filteredVisibleChildren = data.children.filter(node =>
          this.resolveVisibility(node.data?._type)
        );
        const filteredHiddenChildren = data.children.filter(
          node => !this.resolveVisibility(node.data?._type)
        );

        visibleChildren = [...visibleChildren, ...filteredVisibleChildren];
        hiddenChildren = [...hiddenChildren, ...filteredHiddenChildren];
      }

      if (visibleChildren.length) {
        data.children = visibleChildren;
      } else {
        data.children = null;
      }

      if (hiddenChildren.length) {
        data._children = hiddenChildren;
      } else {
        data._children = null;
      }

      if (Array.isArray(data.children)) {
        data.children?.forEach(d => traverse(d as any));
      }

      if (Array.isArray(data._children)) {
        data._children?.forEach(d => traverse(d as any));
      }
    };

    this.root.children?.forEach(data => traverse(data as any));

    this.enableFixNodesPosition = false;

    const { width, height } = this.element.getBoundingClientRect();
    this.simulation.force('boundary', forceBoundary(0, 0, width, height));

    this.renderNodes();
    this.enableFixNodesPosition = false;
  }

  removeFixedNodesPosition() {
    const traverse = (data: Node<Resouce>) => {
      data.fx = null;
      data.fy = null;

      if (Array.isArray(data.children)) {
        data.children?.forEach(d => traverse(d as any));
      }

      if (Array.isArray(data._children)) {
        data._children?.forEach(d => traverse(d as any));
      }
    };

    this.root.children?.forEach(data => traverse(data as any));

    this.enableFixNodesPosition = false;

    const { width, height } = this.element.getBoundingClientRect();
    this.simulation.force('boundary', forceBoundary(0, 0, width, height));
  }

  /**
   * To create D3 Node to Node Location external connection.
   * Or to get data flow connections between applications and services.
   */
  getExternalConnections() {
    const connections: ExternalConnection[] = [];
    const locationConnectionMap: LocationConnectionMap = {};

    const traverse = (
      node: Node<Resouce>,
      parentId: string,
      externalConn: any
    ) => {
      // If node's children are visible then we don't need to pass current node's resouceId
      if (node.children) {
        node.children.forEach(d => traverse(d as any, '', externalConn));
      }

      // If node's children are not visible then we pass current node's resouceId if current node is visible
      // otherwise we pass his parent nodes resouceId
      if (node._children) {
        node._children.forEach(d =>
          traverse(
            d as any,
            parentId ? parentId : node.data?.resource_id,
            externalConn
          )
        );
      }

      // Finally when we reach the inner node with connection metadata,
      // We know that if parentId present then that node is not visible and we need to use parentId instead of its resouceId
      if (
        node?.data?.resource_id === externalConn?.source ||
        node?.data?.resource_id === externalConn?.target
      ) {
        let resource_id,
          label = '',
          type = '';
        if (node?.data?.resource_id === externalConn?.source) {
          resource_id = externalConn?.target;
        }
        if (node?.data?.resource_id === externalConn?.target) {
          resource_id = externalConn?.source;
        }

        // Note: resourceId will always be the id of the node that is visible in the overlay.
        const resourceId = parentId
          ? parentId
          : node?.data?.entityType === 'gateway' &&
            this?.selectedExpansionLevel === 2
          ? node?.data?.deployed_in_depl_env_id
            ? node?.data?.deployed_in_depl_env_id
            : node?.data?.resource_id
          : node?.data?.resource_id;

        // if the connection is between app and service in same location
        // Note: Here we are generating unique key by using unique id's of 2 app and service.
        // We sort them to prevent duplicate entry in locationConnectionMap (ie to prevent duplicate connections lines between same set of app and services).
        const key = [node.data?.resource_id, resource_id].sort().toString();

        locationConnectionMap[key] = locationConnectionMap[key]
          ? {
              ...locationConnectionMap[key],
              // Added includes check to prevent duplicate start and end values in this array.
              // This happens when we have duplicate DEPL_HAS_FLOW in an deployment due to data corruption
              points: !locationConnectionMap[key].points?.includes(resourceId)
                ? [...locationConnectionMap[key].points, resourceId]
                : [...locationConnectionMap[key].points],
              type:
                locationConnectionMap[key]?.type === '' ||
                locationConnectionMap[key]?.type === undefined
                  ? resourceId === externalConn?.source ||
                    resourceId === externalConn?.target
                    ? externalConn?.type === 'POLICY'
                      ? ''
                      : externalConn?.type
                    : ''
                  : locationConnectionMap[key]?.type,
            }
          : {
              label,
              points: [resourceId],
              type:
                resourceId === externalConn?.source ||
                resourceId === externalConn?.target
                  ? externalConn?.type === 'POLICY'
                    ? ''
                    : externalConn?.type
                  : '',
            };
      }
    };
    for (const externalConn of this.externalConns) {
      traverse(this.root as any, '', externalConn);
    }
    // The locationConnectionMap contain unique instance of gateway to gateway connections in the same location.
    // We need to iterate over it to create connections between them;
    for (var key in locationConnectionMap) {
      const { label, points, type } = locationConnectionMap[key];

      if (points && points[0] && points[1]) {
        if (
          points[0] + ',' + points[1] === key ||
          points[1] + ',' + points[0] === key
        ) {
        } else {
          connections.push({
            label,
            start: points[0],
            end: points[1],
            type: type,
          });
        }
      }
    }
    return connections;
  }

  /**
   * Checks the service is inside a RHSI namespace
   * @param serviceDeplId
   * @param resourceId
   * @returns
   */
  isServiceInLocalNamespace(serviceDeplId: string, resourceId: string) {
    // Check whether service belongs to the namespace and avoid policy connection
    const servicePartitionId = this.resouceMap[serviceDeplId]?.partition_id;
    if (servicePartitionId && resourceId === servicePartitionId) {
      const namespace = this.resouceMap[servicePartitionId];
      return (
        namespace?.network_segment_id &&
        namespace.network_segment_id !== DEFAULT_NETWORK_SEGMENT_ID
      );
    }
    return false;
  }

  getApplicationConnections() {
    const connections: Connection[] = [];
    if (Array.isArray(this.dataFlows)) {
      for (const dataFlow of this.dataFlows) {
        const references = dataFlow._references;
        let start = ''; // from field can be multiple in case of RHSI network segment policies
        let end = '';
        if (Array.isArray(references)) {
          // TODO: Currenly this codebase only support policy between multiple application & partition with a single service
          for (const reference of references) {
            if (reference._edgeType === 'DEPL_HAS_FLOW') {
              start = reference._fromUniqueId;

              end = reference._toUniqueId;
              connections.push({ start, end });
            }
          }
        }
      }
    }

    return connections;
  }

  getPolicyConnections() {
    const connections: Connection[] = [];
    if (Array.isArray(this.policies)) {
      for (const policy of this.policies) {
        const references = policy._references;
        const nwSegId = policy?.network_segment_id;
        let startEdges = [];
        let endEdges = [];
        if (Array.isArray(references)) {
          const filteredNamespaces = this.namespaces.filter(
            namespace => namespace.network_segment_id === nwSegId
          );
          for (const namespace of filteredNamespaces) {
            startEdges.push(namespace.resource_id);
          }
          for (const reference of references) {
            let service;
            if (reference._edgeType === 'ALLOW_CONN_TO') {
              service = reference._toUniqueId;

              const refs = this.resouceMap[service]._references;
              if (refs) {
                for (const ref of refs) {
                  if (ref._edgeType === 'SVC_HAS_SVCD') {
                    endEdges.push(
                      ref._toUniqueId === service
                        ? ref._fromUniqueId
                        : ref._toUniqueId
                    );
                  }
                }
              }
            }
          }

          for (const start of startEdges) {
            for (const end of endEdges) {
              if (!this.isServiceInLocalNamespace(end, start))
                connections.push({ start, end });
            }
          }
        }
      }
    }

    return connections;
  }

  getGatewayConnections() {
    const connections: Connection[] = [];
    const gatewaySet = new Set();

    if (Array.isArray(this.gateways)) {
      for (const gateway of this.gateways) {
        const references = gateway._references;
        if (Array.isArray(references)) {
          for (const reference of references) {
            const key = [reference?._fromUniqueId, reference?._toUniqueId]
              .sort()
              .toString();

            if (!gatewaySet.has(key)) {
              gatewaySet.add(key);
              connections.push({
                start: reference?._fromUniqueId,
                end: reference?._toUniqueId,
              });
            }
          }
        }
      }
    }

    return connections;
  }

  initZoom() {
    d3.select('.topology-container svg').call(this.zoom as any);
  }

  handleZoom(e: any) {
    d3.select('.topology-container svg g').attr('transform', e.transform);
  }

  zoomIn() {
    d3.select('.topology-container svg')
      .transition()
      .call(this.zoom.scaleBy as any, 2.5);
  }

  zoomOut() {
    d3.select('.topology-container svg')
      .transition()
      .call(this.zoom.scaleBy as any, 0.6);
  }

  resetZoom() {
    d3.select('.topology-container svg')
      .transition()
      .call(this.zoom.scaleTo as any, 1);
  }

  /**
   * To resize the canvas when user minimize or maximize the window.
   * Triggered on window.resize event.
   */
  resizeCanvas() {
    const { width, height } = this.element.getBoundingClientRect();

    d3.select('.topology-container svg')
      .attr('width', width)
      .attr('height', height);
  }

  async getGatewayLatency(sourceGatewayId: string, targetGatewayId: string) {
    try {
      const avgTunnelLatencyQuery =
        sourceGatewayId && targetGatewayId
          ? getTunnelAverageLatencyQuery(sourceGatewayId, targetGatewayId)
          : '';

      const response = await getMetrics(avgTunnelLatencyQuery, false);
      const metrics = response.data.result[0];

      if (Array.isArray(metrics?.value)) {
        // Since this is a round trip latency we need to divide it by 2
        const key = `${sourceGatewayId}-${targetGatewayId}`;
        this.getewayLatency[key] = metrics?.value[1]
          ? (metrics?.value[1] / 1000 / 2).toFixed(2).replace(/[.,]00$/, '')
          : '';

        this.renderNodes();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getAppServiceLatency(sourceId: string, targetId: string) {
    try {
      const source = this.resouceMap[sourceId];
      const target = this.resouceMap[targetId];

      let appId, serviceId, appNamespace, svcNamespace;

      if (source.entityType === 'deployment') {
        if (source.network_segment_id === DEFAULT_NETWORK_SEGMENT_ID) return;

        appId = source.application_id;
        appNamespace = source.partition_id;

        serviceId = target.service_uniqueId;
        svcNamespace = target.partition_id;
      } else {
        // To handle the edge case where target become app deployment
        if (target.network_segment_id === DEFAULT_NETWORK_SEGMENT_ID) return;

        appId = target.application_id;
        appNamespace = target.partition_id;

        serviceId = source.service_uniqueId;
        svcNamespace = source.partition_id;
      }

      const avgAppServiceQuery =
        appId && appNamespace && serviceId && svcNamespace
          ? getAppServiceAverageLatencyQuery(
              appId,
              serviceId,
              appNamespace,
              svcNamespace
            )
          : '';

      const response = await getMetrics(avgAppServiceQuery, false);
      const metrics = response.data.result[0];
      if (Array.isArray(metrics?.value)) {
        const key = `${sourceId}-${targetId}`;
        const result = metrics.value[1];
        const latency = result ? result : undefined;

        this.appServiceLatency[key] = !isNaN(latency)
          ? Number(latency / 1000)
              .toFixed(0)
              .replace(/[.,]00$/, '')
          : '-';

        this.renderNodes();
      }
    } catch (error) {
      console.error(error);
    }
  }
}

export default D3Graph;
