import React, { useState, useEffect, useRef } from 'react';
import { TooltipDefinition } from 'carbon-components-react';
import { splitStringToArray } from '../../lib/utils';

import './Overflow.scss';

interface Props {
  maxNoOfLines?: number;
  align?: 'center' | 'start' | 'end';
  toolTipDirection?: 'top' | 'bottom';
}

const Overflow: React.FC<Props> = ({
  children,
  maxNoOfLines = 1,
  align = 'center',
  toolTipDirection = 'bottom',
  ...rest
}) => {
  if (!React.isValidElement(children) || React.Children.count(children) !== 1) {
    throw new Error('This component must have 1 element child');
  }

  const [innerText, setInnerText] = useState('');
  const [lineBreaks, setLineBreaks] = useState<string[]>([]);
  const [overflow, setOverflow] = useState(false);

  const ref = useRef<HTMLElement>(null);
  const tooltipContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (tooltipContainerRef.current) {
      const lastNode = getDeepestNode(tooltipContainerRef.current);
      if (lastNode) {
        lastNode.textContent = getTextContents();
      }
    }
  });

  const getTextContents = () => {
    let textContent = '';

    if (Array.isArray(lineBreaks)) {
      lineBreaks.forEach((line, index) => {
        if (index < maxNoOfLines) {
          textContent += line;
        }
      });
    }

    const textContentArray = splitStringToArray(textContent);

    return textContentArray.slice(0, -3).join('').trimEnd() + '...';
  };

  const getLineBreaks = (node: Node) => {
    // we only deal with TextNodes
    if (!node || !node.parentNode || node.nodeType !== 3) return [];

    // our Range object form which we'll get the characters positions
    const range = document.createRange();
    // here we'll store all our lines
    const lines = [];
    // begin at the first char
    range.setStart(node, 0);
    // initial position
    let prevBottom = range.getBoundingClientRect().bottom;
    let str = node.textContent;
    if (str) {
      let current = 1; // we already got index 0
      let lastFound = 0;
      let bottom = 0;

      // iterate over all characters
      while (current <= str.length) {
        // move our cursor
        range.setStart(node, current);
        if (current < str.length - 1) range.setEnd(node, current + 1);
        bottom = range.getBoundingClientRect().bottom;
        if (bottom > prevBottom) {
          // line break
          lines.push(
            str.substr(lastFound, current - lastFound) // text content
          );
          prevBottom = bottom;
          lastFound = current;
        }
        current++;
      }

      // push the last line
      lines.push(str.substr(lastFound));
    }

    return lines;
  };

  const getDeepestNode = (node: Node) => {
    let result = node;
    let maxDepth = 0;

    const traverse = (node: Node, currentDepth: number) => {
      let currentResult = node;

      if (node.hasChildNodes()) {
        currentDepth++;
        node.childNodes.forEach(children => {
          currentResult = traverse(children, currentDepth);

          if (currentDepth >= maxDepth) {
            maxDepth = currentDepth;
            result = currentResult;
          }
        });
      }

      return currentResult;
    };

    traverse(node, 0);

    return result;
  };

  const handleResize = () => {
    setOverflow(false);

    if (ref.current) {
      const lastNode = getDeepestNode(ref.current);
      const lineBreaks = getLineBreaks(lastNode);

      if (lineBreaks.length > maxNoOfLines) {
        if (lastNode.textContent) {
          setInnerText(lastNode.textContent);
          setLineBreaks(lineBreaks);
          setOverflow(true);
        }
      }
    }
  };

  return overflow ? (
    <div className='overflow-component' ref={tooltipContainerRef}>
      <TooltipDefinition
        {...rest}
        className='overflow-wrap'
        direction={toolTipDirection}
        align={align}
        tooltipText={innerText}
      >
        {children}
      </TooltipDefinition>
    </div>
  ) : (
    React.cloneElement(children, {
      ...children.props,
      ref,
    })
  );
};

export default Overflow;
